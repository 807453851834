.satellite_config_item{
    display: flex;
    padding: 4px 12px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    span{
        color: #F0F0F0;
        font-family: Montserrat;
        font-size: 12pt;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.satellite_config_item .input_modal{
color: #8E8E8E;
font-family: Montserrat;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.groupConfigSameCaseMessage{
display: flex;
padding: 4px 8px;
justify-content: center;
align-items: center;
gap: 10px;
align-self: stretch;
}

.configInfo{
    color: #F0F0F0;
font-family: Montserrat;
font-size: 12px;
font-style: italic;
font-weight: 400;
line-height: normal;
opacity: 0.8;
}
