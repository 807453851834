.slider_header {
    width: 100%;
    height: 13%;
    box-shadow: 0px 10px 30px rgb(0, 0, 0, 0.5);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.slider_header__btn {
    transition : all 0.3s ease;
    background: rgba(240, 240, 240, 0.05);
    border-radius: 5px;
    border: 0.1px solid rgba(240, 240, 240, 0.1);
    box-shadow: 0px 0px 2px 0px rgba(188, 188, 188, 0.2);
    backdrop-filter: blur(125px);
    width: 100%;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #bbb;
    .slider_header__btn__icon {
        display: none;
        padding: 7px;
        background: rgba(240, 240, 240, 0.073);
        border-radius: 100%;
        color: rgba(255, 255, 255, 0.4)
    }
}
.slider_header__btn:hover {
    justify-content: space-between;
    border: 0.1px solid rgba(230, 223, 132, 0.3);
    background: rgba(240, 240, 240, 0.07);
    .slider_header__btn__icon {
        display: flex;
    }
}

.slider_caontainer {
    width: 100%;
    height: 87%;
    padding: 20px 10px 0px 10px;    
}

.side_bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 350px;
    height: 100%;
    transition : all 1s ease;
    transform : translate(-95%);
    background: rgb(13, 13, 14);
    border-radius: 0px 8px;
    border: 0.5px solid rgba(240, 240, 240, 0.2);
    box-shadow: 0px 0px 2px 0px rgba(188, 188, 188, 0.2);
    backdrop-filter: blur(125px);
    z-index: 1;
    .close__side_bar_icon{
        transform: rotate(180deg);
    }
    .slider_caontainer{
        overflow: hidden;
    }
}

.close__side_bar_icon {
    display: none;
    position: absolute;
    right: -12pt;
    top: 20px;
    width: 30px;
    height: 30px;
    padding: var(--8, 8px) 0px var(--8, 8px) 1px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 20px;
    background: #252E0B;
    box-shadow: 0px 4px 10px 0px rgba(56, 56, 56, 0.25);
    cursor: pointer;
    transition: transform 0.3s ease;
    z-index: 9;
}

.side_bar:hover {
    transform : translate(0);
    .close__side_bar_icon{
        display: flex;
        transform: rotate(0);
    }
}
.show_side_bar {
    transform : translate(0);
    .close__side_bar_icon{
        transform: rotate(0);
    }
    .slider_caontainer{
        overflow-y: scroll;
    }
}

.slider_content {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 7px;
    border-radius: 4px;
}


.slider_content_header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.03);
    padding: 10px;
    height: 50px;
    border-radius: 4px;
    box-shadow: 0px 2px 1px rgba(240, 240, 240, 0.03);
    cursor: pointer;
    transition : all 300ms ease;
}
.header_text{
    font-size: 18px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.6);
}


.slider_content_header .name_section{
    display: flex;
    width: 100%;
}
.slider_content_header .btn_action_section{
    display: none;
    gap: 5px;
    align-items: center;
    justify-content: center;
}
.slider_content_header .btn_action_section.active{
    display: flex
}

.slider_content:hover{
    background: rgba(136, 120, 120, 0.145);
    .slider_content_header .name_section{
        display: flex;
        width: 70%;
    };
    .slider_content_header .btn_action_section{
        display: flex;
    }
}


.slider_content.active{
    background: rgba(98, 85, 85, 0.253);
}
.sat__delete__button{
    display: flex;
    height: 30px;
    width: 30px;
    align-items: center;
    justify-content: center;
    border-radius: 4pt;
    background: transparent;
    svg{
            height: 24px;
            width: 24px;
        }
    &:hover{
        border: 0.5pt solid rgba(240, 240, 240, 0.10);
        background: rgba(9, 9, 9, 1.0);
        backdrop-filter: blur(20pt);
        svg{
            fill: rgb(242, 115, 115);
        }
    }
}