.scenario__container__main{
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: start;
  flex-direction: start;
  justify-content: space-between;
  color: #F0F0F0;
}

.scenario__header__btn__container {
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;
  top: 20px;
  right: 20px;
  position: absolute;
  z-index: 5;
  .right__button__container{
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(10px);
    .right__section__button{
      background: transparent;
      padding: 0;
      margin: 0;
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0.5px solid rgba(240, 240, 240, 0.20);
      background: rgba(0, 0, 0, 0.50);
      box-shadow: 0px 4px 8px 0px rgba(160, 160, 160, 0.08);
      backdrop-filter: blur(15px);
      position: relative;
      &.single__btn{
          border-radius: 8px;
      }
      &.center__btn {
          border-radius: 0px;
      }
      &.right__btn {
          border-radius: 0px 8px 8px 0px;
      }
      &.left__btn{
          border-radius: 8px 0px 0px 8px;
      }
      &.hide__btn{
          display: none;
      }
      &.active{
          border: 0.5px solid rgba(204, 245, 78, 0.50);
          background: #1C2113;
          svg {
              stroke: #CCF54E;
              stroke-width: 0.5px;
          }
      };
      &:hover {
          background: #1C2113;
          svg {
              stroke: #CCF54E;
              stroke-width: 0.5px;
          }
      }
      &.disabled{
          pointer-events: none;
          cursor: default;
          border: 0.5px solid #313131;
          svg {
              opacity: 0.3;
          }
      }
      .pinned{
          position: absolute;
          top: 0px;
          right: 0px;
      }
    }
  }
  .right__button__loading__container{
    display: flex;
    padding: 0px 12px;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(10px);
    background: #191A1A;
    border: 0.5px solid rgba(240, 240, 240, 0.20);
    height: 40px;
    gap: 8px;
    border-radius: 8px;
    color: #CCF54E;
    font-size: 14px;
    font-weight: 500;
  }
  .deploy__notification__container{
    display: flex;
    color: #F0F0F0;
    font-size: 14px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 8px 14px;
    width: 165px;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-weight: 500;
    border-radius: 4px;
    background: rgba(25, 26, 26, 0.40);
    box-shadow: 0px 4px 20px 0px rgba(15, 5, 41, 0.25);
    backdrop-filter: blur(15px);
    position: absolute;
    top: 50px;
    right: 0px;
    animation: animate_right 0.3s ease-in-out;
    -webkit-animation: animate_right 0.3s ease-in-out;
  }
}

@keyframes animate_right {
  0% {
    right: -200px;
  }
  100% {
    right: 0px;
  }
}

.scenario_mainModal {
    width: 400px;
    position: absolute;
    top: 20px;
    left: 20px;
    float: left;
    border-radius: 0px 8px;
    border: 0.5px solid rgba(240, 240, 240, 0.2);
    background: rgba(0, 0, 0);
    box-shadow: 0px 0px 20px 0px rgba(52, 52, 52, 0.3);
    backdrop-filter: blur(125px);
    transition: 300ms;
    z-index: 99;
  }

.additional__orbit__details__modal{
    width: 430px;
    position: absolute;
    top: -2px;
    left: 410px;
    float: left;
    border-radius: 0px 8px;
    border: 0.5px solid rgba(240, 240, 240, 0.2);
    background: rgba(17, 17, 17);
    box-shadow: 0px 0px 20px 0px rgba(52, 52, 52, 0.3);
    backdrop-filter: blur(125px);
    transition: 300ms;
    z-index: 10;
}
.additional__orbit__details__modal__container{
  height: 350px;
  overflow-y: auto;
  padding-bottom: 10px;
  background: rgb(204, 245, 78, 0.05);
}

.additional_objects_btn{
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background: #19191A;
}
.additional_orbit_row{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.additional_orbit_row_btn_container{
  display: none !important;
  display: flex;
  align-items: center;
}
.additional_orbit_row:hover{
  .additional_orbit_row_btn_container{
    display: flex !important;
  }
}
  .scenario_mainModal .largerMainModal {
    width: 484px;
  }
  
  .scenario_mainModal .sidebarOpenModal {
    left: 280px;
  }
  
  .scenario_mainModal .modal__Header {
    display: block;
    width: 100%;
    height: 40px;
    padding: 4px;
    border-radius: 0px 8px 0px 0px;
    border-style: solid;
    border-width: 0px;
    border-bottom-width: 0.1px;
    border-image: linear-gradient(15deg, #fff, #000) 1;
  }
  
  .scenario_mainModal .modalHeaderImg {
    height: 32px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    float: left;
  }
  
  .scenario_mainModal .modal__Header svg {
    width: 100%;
    height: 16px;
  }
  
  .scenario_mainModal .modal__Header span {
    padding-left: 6px;
    height: 100%;
    float: left;
    color: #ccf54e;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.56px;
    line-height: 2;
  }
  
  .scenario_mainModal .modalCloseBtn {
    float: right;
    padding-right: 14px;
    padding-top: 0px;
    background: transparent;
    height: 32px;
  }
  
  .scenario_mainModal .modalComponentBox {
    display: flex;
    padding: 6px 16px 14px;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
  }
  
  .scenario_mainModal .modalComponentItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80px;
    width: 33%;
    position: relative;
  }
  
  .scenario_mainModal .modalComponentTwoLineItem {
    height: 100px;
  }
  
  .scenario_mainModal .modalTwoComponentItem {
    width: 50%;
  }
  
  .scenario_mainModal .modalComponentBtnBox {
    width: 100%;
    margin-bottom: 16px;
  }
  
  .scenario_mainModal .modalComponentBtn {
    padding: 8px;
    height: 44px;
    margin: 0px 26px;
    width: 44px;
    transition-duration: 0.2s;
    transition-timing-function: linear;
    background: transparent;
    border-radius: 4px;
  }
  
  .scenario_mainModal .modalComponentTwoLineBtn {
    position: absolute;
    top: 10px;
  }
  
  .scenario_mainModal .modalTwoComponentBtn {
    margin: 0px 50px;
  }
  
  .scenario_mainModal .modalComponentBtn:hover {
    border-radius: 4px;
    border: 0.5px solid #f0f0f0;
    background: rgba(240, 240, 240, 0.1);
  }
  
  .scenario_mainModal .dropdownSpacer {
    margin: 14px;
  }
  
  .scenario_mainModal .activeModalComponentBtn {
    background: #262b1a !important;
    position: relative;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  .scenario_mainModal .activeModalComponentBtn::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 4px;
    padding: 0.5px;
    background: linear-gradient(135deg, rgba(113, 128, 79, 1), rgba(240, 240, 240, 0));
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
  
  .scenario_mainModal .modalComponentTxt {
    color: #f0f0f0;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px; /* 175% */
    position: absolute;
    bottom: -4px;
    text-align: center;
    vertical-align: bottom;
  }
  
  .scenario_mainModal .modalComponentTwoLineTxt {
    color: #f0f0f0;
    font-family: Montserrat;
    overflow: hidden;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5;
    position: absolute;
    bottom: -4px;
    text-align: center;
    vertical-align: bottom;
  }
  
  .scenario_mainModal .modalSubHeader {
    display: flex;
    padding: 4px 14px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  }
  
  .scenario_mainModal .modalCheckboxDiv {
    width: 100%;
    margin: 0px 14px 0px 15px;
    padding: 8px 0px;
    display: block;
    align-items: center;
    position: relative;
    height: 40px;
    /* box-shadow: 0px 4px 20px 0px rgba(10, 10, 10, 0.3); */
  }
  
  .scenario_mainModal .modalCheckboxSubDiv {
    width: 33.33%;
    float: left;
  }
  
  .scenario_mainModal .modalBoxShadowDiv {
    box-shadow: 0px 4px 20px 0px rgba(10, 10, 10, 0.3);
  }
  
  .scenario_mainModal .modalCheckbox {
    width: 16px;
    height: 16px;
    float: left;
    margin-right: 5px;
    background: transparent;
    background-color: transparent;
    border-radius: 3px;
    accent-color: rgba(15, 15, 15, 0.3);
    visibility: hidden;
  }
  
  .scenario_mainModal .modalCheckboxText {
    color: #fff;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 2;
    float: left;
  }
  .scenario_mainModal .modalCheckboxTextRight {
    margin-left: 27px;
  }
  
  .scenario_mainModal .disabledBtn {
    pointer-events: none;
    opacity: 0.6;
  }
  
  .scenario_mainModal .modalGreenSubHeader {
    color: #33e18f;
    font-size: 13px;
  }
  
  .scenario_mainModal .topMarginClass {
    margin-top: 14px;
  }
  
  .scenario_mainModal .modalWhiteSubHeader {
    color: #f0f0f0;
    font-size: 13px;
  }
  
  .scenario_mainModal .modalCheckBoxBtn {
    background-color: transparent;
    position: relative;
    left: -6px;
    float: left;
  }
  
  .scenario_mainModal .modalDropDownIconBtn {
    background-color: transparent;
    width: 24px;
    height: 24px;
    position: absolute;
    left: -10px;
  }
  
  .scenario_mainModal .modalAnalysisDiv {
    display: flex;
    padding: 8px 14px 8px 14px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  }
  
  .scenario_mainModal .modalAnalysisDivLong {
    padding: 8px 14px;
  }
  
  .scenario_mainModal .modalAnalysisText {
    color: #f0f0f0;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  
  .scenario_mainModal .modalAnalysisInput {
    display: flex;
    width: 80px;
    height: 24px;
    padding: 0px 6px;
    align-items: center;
    border-bottom: 0.5px solid #2465b2;
    background: rgba(240, 240, 240, 0.1);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    transition: background-color 0.5s ease-in-out;
  }
  
  .scenario_mainModal .modalAnalysisInput:not(:placeholder-shown) {
    background: rgba(52, 52, 52, 0.15);
  }
  
  .scenario_mainModal .modalAnalysisInput.modalAnalysisInputText {
    width: calc(100% - 24px);
    margin: 0px 14px 14px;
    height: 31px;
  }
  
  .scenario_mainModal .modalSubComponentBox {
    height: 80px;
  }
  
  .scenario_mainModal .modalSubComponentItem {
    display: flex;
    flex-direction: column;
    padding: 4px 8px;
    height: 50px;
    background: transparent;
    transition-timing-function: linear;
    transition-duration: 0.2s;
  }
  
  .scenario_mainModal .modalSubComponentItem:hover {
    border-radius: 4px;
    border: 0.5px solid #f0f0f0;
    background: rgba(240, 240, 240, 0.1);
  }
  
  .scenario_mainModal .activeModalSubComponentItem {
    border-radius: 4px;
    border: 0.5px solid #ccf54e !important;
    background: rgba(204, 245, 78, 0.1) !important;
  }
  
  .scenario_mainModal .modalSubComponentBtnBox {
    width: 100%;
    margin-bottom: 16px;
  }
  
  .scenario_mainModal .modalSubComponentTxt {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    bottom: 0px;
  }
  
  .scenario_mainModal .modalFooter {
    display: flex;
    width: 100%;
    height: 65px;
    padding: 14px;
    overflow: hidden;
    align-items: center;
    justify-content: space-between;
    border-style: solid;
    border-width: 0px;
    border-top-width: 0.1px;
    border-image: linear-gradient(135deg, #fff, #000) 1;
  }
  
  .scenario_mainModal .modalFooter .back_close_btn,
  .scenario_mainModal .modalFooter .save_close_btn,
  .scenario_mainModal .modalFooter .save_btn {
    padding: 8px 24px;
    color: #f0f0f0;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  .scenario_mainModal .modalFooter .back_close_btn:hover {
    background: #45570D(240, 240, 240, 0.1);
  }
  
  .scenario_mainModal .modalFooter .back_close_btn {
    background: transparent;
    border-radius: 4px;
    border: 0.5px solid rgba(240, 240, 240, 0.5);
    transition: 300ms;
  }
  .scenario_mainModal .modalFooter .save_btn {
    border-radius: 4px;
    background: #58700D;
    border: 1px solid #45570D;
    transition: 300ms;
  }
  .scenario_mainModal .modalFooter .save_close_btn {
    border-radius: 4px;
    background: transparent;
    border: 0.5px solid #81A60E;
    color: #81A60E;
    transition: 300ms;
  }

  .scenario_mainModal .modalFooter .back_close_btn:disabled,
  .scenario_mainModal .modalFooter .save_close_btn:disabled,
  .scenario_mainModal .modalFooter .save_btn:disabled {
    opacity: 0.5;
    box-shadow: none;
  }

  .scenario_mainModal .modalFooter .save_btn:hover {
    background: #45570D;
  }
  
  .scenario_mainModal .disabledSubComponentBtn {
    cursor: not-allowed;
    pointer-events: none;
  }
  
  .scenario_mainModal .disabledSubComponentBtn img {
    opacity: 0.4;
  }
  
  .scenario_mainModal .disabledSubComponentBtn .modalSubComponentTxt {
    opacity: 0.4;
  }
  
  .scenario_mainModal .modalCreationMessage {
    position: absolute;
    top: 10vh;
    right: 70px;
    height: 36px;
    padding: 8px;
    border-radius: 4px;
    border: 0.5px solid #ccf54e;
    gap: 8px;
    color: #ccf54e;
    background: #27334b80;
    text-align: center;
    line-height: 100%;
    backdrop-filter: blur(125px);
  }
  
  .scenario_mainModal .modalCreationMessage.sideReportbarOpen {
    right: 320px;
  }
  
  .scenario_mainModal .modalCreationMessage svg {
    float: left;
    margin-right: 10px;
  }
  
  .scenario_mainModal .modalCreationMessage svg path {
    transform: translate(0%, -10%);
  }
  
  .scenario_mainModal .modalrefOrbitDiv {
    display: flex;
    padding: 8px 14px;
    align-items: center;
    align-content: center;
    margin: 0px 14px;
    flex: 1 0 0;
    flex-wrap: wrap;
    border: 0.5px solid #2465b2;
    background: rgba(255, 255, 255, 0.1);
    min-height: 31px;
  }
  
  .scenario_mainModal .modalrefOrbitDiv::-webkit-scrollbar {
    height: 3.5px;
  }
  
  .scenario_mainModal .modalrefOrbitDiv {
    color: rgba(240, 240, 240, 0.65);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  
  .scenario_mainModal .modalrefOrbitsBtn {
    display: flex;
    padding: 4px 8px;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: #f2a93b;
    color: #0a0a0a;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-right: 14px;
    cursor: pointer;
  }
  
  .scenario_mainModal .modalrefOrbitsBtn.secondLine {
    margin-top: 14px;
  }
  
  .scenario_mainModal .modalrefOrbitsCloseBtn {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 7px;
    justify-content: center;
    align-items: center;
    gap: 14px;
    background: #f2a93b;
    transition: 300ms;
  }
  
  .scenario_mainModal .modalrefOrbitsCloseBtn:hover {
    scale: 1.25;
  }
  
  .scenario_mainModal .modalrefOrbitsViewBtn {
    padding: 0px;
    width: 16px;
    transition: 300ms;
  }
  
  .scenario_mainModal .modalrefOrbitsViewBtn:hover {
    scale: 1.25;
  }
  
  .scenario_mainModal .modal_body_section {
    width: 100%;
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  
  .scenario_mainModal .modalAoiBtn {
    background: #8e7dee;
  }
  .scenario_mainModal .modalGroundStationBlueBtn {
    background: #6cabff;
  }
  
  .scenario_mainModal .modalGroundStationBlueCloseBtn {
    background: #6cabff;
  }
  
  .scenario_mainModal .modalAoiCloseBtn {
    background: #8e7dee;
  }
  
  .scenario_mainModal .modalrefOrbitsSpacer {
    margin-bottom: 14px;
    width: 100%;
    display: flex;
  }
  
  .scenario_mainModal .orbitsBtnSpacer {
    margin-bottom: 6px;
    float: left;
  }
  
  
  .referenceOrbitDiv {
    transition: 300ms;
  }

  .scenario__time__input__container{
    display: flex;
    height: 39px;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 0.5px solid rgba(36, 101, 178, 0.6);
    background: transparent;
    padding: 0 10px;
    color: #F0F0F0;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .scenario__time__input{
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    border-radius: 4px;
    border: none;
    background: transparent;
    color: #F0F0F0;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    transition: background-color 0.3s ease-in-out;
    padding: 0;
    max-width: 70%;
  }
  .scenario__time__input:focus,
  .scenario__time__input:focus-visible{
    border: none;
    outline: none;
  }
  
  .scenario_mainModal .modalInputFullLen {
    display: flex;
    height: 39px;
    border-radius: 4px;
    padding: 0px 14px;
    align-items: center;
    align-self: stretch;
    border-bottom: 0.5px solid #2465b2;
    background: rgba(240, 240, 240, 0.1);
    width: calc(100% - 24px);
    margin: 6px 14px;
    color: rgba(240, 240, 240, 0.5);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    transition: background-color 0.5s ease-in-out;

    &.text__input{
      border: 0.5px solid rgba(240, 240, 240, 0.30);
      background: rgba(10, 10, 10, 0.65);
    }
  }

  .scenario_mainModal .modalInputFullLen:focus {
    background: rgba(52, 52, 52, 0.15);
    border: 0px;
  }
  .scenario_mainModal .modalInputFullLen:not(:placeholder-shown) {
    color: #f0f0f0;
    background: rgba(52, 52, 52, 0.15);
    cursor: default;
    &.text__input{
      border: 0.5px solid rgba(240, 240, 240, 0.30);
      background: rgba(10, 10, 10, 0.65);
    }
  }
  
  .scenario_mainModal .modalGroundStationDiv {
    height: 250px;
  }
  
  .scenario_mainModal .modalGroundStationsBtnDiv {
    margin-bottom: 14px;
  }
  
  .scenario_mainModal .modalSubHeaderDiv {
    height: 23px;
    padding: 4px 14px;
  }
  
  .scenario_mainModal .tableModalOpenModal {
    float: right;
    background-color: transparent;
    padding-right: 25px;
    transition: 400ms;
  }
  
  .scenario_mainModal .tableModalOpenModal:hover {
    scale: 1.3;
  }
  
  .scenario_mainModal .bottomPad {
    padding-bottom: 14px;
  }
  
  .scenario_mainModal .orHorizontalLine {
    width: calc(45% - 14px);
    border-top-color: #f0f0f0 !important;
    float: left;
    border-width: 1px;
  }
  
  .scenario_mainModal .orDiv {
    display: block;
    padding: 0px 14px;
    width: 100%;
    overflow: hidden;
  }
  
  .scenario_mainModal .orText {
    font-size: 14px;
    float: left;
    width: 18%;
    text-align: center;
    padding: 7px;
  }
  
  .scenario_mainModal .bigModalHalfDiv {
    width: 50%;
    padding: 14px;
    float: left;
  }
  
  .scenario_mainModal .bigModalHalfDivLabel {
    font-size: 14px;
    float: left;
    line-height: 1.75;
  }
  
  .scenario_mainModal .bigModalHalfDivInput {
    float: right;
    width: 72px;
    border: 0px;
    border-bottom: 0.5px solid #2465b2;
    padding: 0px 14px;
    font-family: Montserrat;
    font-size: 14px;
    height: 24px;
    background: rgba(255, 255, 255, 0.1);
    color: rgba(240, 240, 240, 0.5);
  }
  
  .scenario_mainModal .modalDiv {
    width: 100%;
    overflow: hidden;
  }
  
  .scenario_mainModal .modalSelect {
    float: right;
    width: 108px;
    height: 24px;
    font-size: 14px;
    padding: 0px 14px;
    justify-content: space-between;
    align-items: center;
    border: 0px;
    border-bottom: 0.5px solid #2465b2;
    background: rgba(52, 52, 52, 0.15);
  }
  
  .scenario_mainModal .modalSelectOption {
    background: rgba(52, 52, 52, 0.15);
    color: #000;
  }
  
  .blockDisplay {
    display: block;
  }
  
  .scenario_mainModal .modalAnalysisDiv.blockDisplay {
    overflow: hidden;
  }
  
  .scenario_mainModal .modalAnalysisText.blockDisplay {
    line-height: 2;
    float: left;
  }
  
  .scenario_mainModal .modalAnalysisInput.blockDisplay {
    float: right;
  }
  
  .scenario_mainModal .modalSelect.blockDisplay {
    float: right;
  }
  
  .scenario_mainModal .inModalTable {
    margin: 0px 14px;
  }
  
  th,
  tr,
  td {
    height: 24px;
  }
  
  .scenario_mainModal .inModalTableColumn {
    padding: 0px 4px !important;
    width: 33%;
  }
  
  .scenario_mainModal .inModaltableInputElement {
    height: 24px !important;
    width: 80% !important;
    padding: 0px !important;
    margin: 2% 10% !important;
    text-align: center;
  }
  
  .scenario_mainModal .modalFlexDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  }
  
  .scenario_mainModal .modalThirdsDiv {
    display: flex;
    font-size: 14px;
    line-height: 2;
  }
  
  .scenario_mainModal .modalThirdsDiv input {
    width: 80px !important;
    height: 24px !important;
    margin: 0px !important;
    margin-left: 14px !important;
  }
  

  .scenario_mainModal .modalSelectBtn {
    width: 100%;
    background-color: transparent;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    color: #f0f0f0;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  
  .scenario_mainModal .modalSelectBtn:hover {
    cursor: pointer;
  }
  
  .scenario_mainModal .modalSelectBtn .modalSelectOptionBtn {
    background: rgba(15, 15, 15, 0.3);
    background-color: rgba(15, 15, 15, 0.3);
    box-shadow: 0px 0px 20px 0px rgba(52, 52, 52, 0.3);
    backdrop-filter: blur(125px);
    color: #000;
  }
  
  
  .scenario_mainModal .css-1p3m7a8-multiValue {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-width: 0;
    background-color: hsl(0, 0%, 90%);
    border-radius: 2px;
    margin: 2px;
    box-sizing: border-box;
    border-radius: 4px;
    background: #f2a93b !important;
    padding: 4px 8px;
    font-weight: 600;
    font-size: 14px;
  }
  
  .scenario_mainModal .tableModalDiv {
    border-radius: 0px 8px;
    border: 0.5px solid rgba(240, 240, 240, 0.2);
    background: rgba(15, 15, 15, 0.4);
    box-shadow: 0px 0px 20px 0px rgba(43, 43, 43, 0.3);
    backdrop-filter: blur(125px);
    position: absolute;
    left: 400px;
    top: 90px;
    transition: 300ms;
  }
  
  .scenario_mainModal .tableModalDiv.isSidebarOpen {
    left: 620px;
  }
  
  .scenario_mainModal .tableModalHeader {
    display: flex;
    padding: 4px 14px;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    color: #ccf54e;
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 100%;
    height: 30px;
  }
  
  .scenario_mainModal .tableModalBlackButtonDiv {
    margin: 14px;
    height: 30px;
  }
  
  .scenario_mainModal .tableModalTable {
    margin: 0px 14px 14px;
    width: calc(100% - 24px);
  }
  
  .scenario_mainModal .tableModalSelect {
    background-color: transparent;
  }
  
  .scenario_mainModal .tableModalColumn {
    max-width: 128px;
    width: 17%;
    margin: 2%;
    padding: 0px 6px;
    text-align: center;
  }
  
  .scenario_mainModal .tableModalCopyColumn {
    width: 5%;
    padding: 0px 14px;
  }
  
  .scenario_mainModal .tableModalRow {
    height: 40px;
  }
  
  .scenario_mainModal .tableModalHeaderText {
    color: #f0f0f0;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 128px;
  }
  
  .scenario_mainModal .tableCopyBtn {
    background: transparent;
    transition: 300ms;
    padding: 0px;
  }
  
  .scenario_mainModal .tableCopyBtn:hover {
    scale: 1.5;
  }
  
  .scenario_mainModal .tableInputElement {
    border: 0px;
    border-bottom: 0.5px solid #2465b2;
    padding: 0px 5px;
    width: 100%;
    font-family: Montserrat;
    font-size: 14px;
    height: 35px;
  }
  
  .scenario_mainModal .tableInputElement:placeholder-shown {
    background: rgba(255, 255, 255, 0.1);
    color: rgba(240, 240, 240, 0.5);
  }
  
  .scenario_mainModal .tableInputElement:not(:placeholder-shown) {
    background: rgba(255, 255, 255, 0);
    color: #f0f0f0;
  }
  
  .scenario_mainModal .tableModalHeaderIcon {
    padding-right: 14px;
  }
  
  .scenario_mainModal .tableModalElement {
    max-width: 128px;
    margin: 4px auto;
  }
  
  .scenario_mainModal .addPanelDiv {
    margin: 0px 14px 14px;
    text-align: right;
  }
  
  .scenario_mainModal .addTableModalPanelBtn {
    color: rgba(240, 240, 240, 0.7);
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    background: transparent;
    transition: 300ms;
  }
  
  .scenario_mainModal .addTableModalPanelBtn:hover {
    scale: 1.1;
  }
  

.scenario_mainModal .greenTickIcon{
    display: block;
    float: right;
    position: absolute;
    right: 25px;
}

.scenario_mainModal .highLightedBackground{
  background: rgba(203, 245, 78, 0.09);
    padding-bottom: 15px; 
}

.disable__components{
  pointer-events: none;
  opacity: 0.5;
}

.timeline_skeleton{
  width: 100%;
  display: flex;
  border-radius: 8px;
  opacity: 0.43;
  background: linear-gradient(90deg, rgba(240, 240, 240, 0.20) 0%, rgba(240, 240, 240, 0.06) 51.71%);
  animation: blink 2s infinite;
  -webkit-animation: blink 2s infinite;
}
@keyframes blink {
  0% {
    opacity: 0.43;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0.43;
  }
}


/* // MUI CALENDER MODIFICATION // */
.MuiDateCalendar-root,
.MuiYearCalendar-root,
.MuiMultiSectionDigitalClock-root{
  max-height: 300px !important;
}
.MuiInputBase-root-MuiOutlinedInput-root{
  border: 0.5px solid rgba(36, 101, 178, 0.6) !important;
  svg{
    fill: #F0F0F0 !important;
  }
}
.MuiPaper-root-MuiPickersPopper-paper{
  background: #08172F !important;
  svg{
    fill: #F0F0F0 !important;
  }
}
.MuiPickersDay-root,
.MuiDateCalendar-root,
.MuiPickersYear-yearButton,
.MuiPickersMonth-monthButton,
.MuiButtonBase-root-MuiPickersDay-root,
.MuiTypography-root-MuiDayCalendar-weekDayLabel,
.MuiButtonBase-root-MuiMenuItem-root-MuiMultiSectionDigitalClockSection-item{
  color: #F0F0F0 !important;
  &:disabled{
    opacity: 0.38 !important;
  }
}

.upload_scenario_btn{
  background: rgba(240, 240, 240, 0.2);
  border-radius: 4px;
  height: 35px;
  font-size: 14px;
  &:hover{
    background: rgba(240, 240, 240, 0.3);
  }
  &:disabled{
    opacity: 0.6;
    pointer-events: none;
  }
}


.upload__scenario__toggle__btn{
    width: 24px;
    height: 16px;
    display: flex;
    align-items: center;
    border-radius: 2px;
    position: relative;
    background: rgba(240, 240, 240, 0.2);
    &::before{
        content: '';
        width: 10px;
        height: 10px;
        border-radius: 2px;
        background: #F0F0F0;
        position: absolute;
        left: 2px;
        right: unset;
        transition: all 0.3s ease;
    }
    &.active {
        &::before {
            background: rgb(204, 245, 78);
            right: 2px;
            left: unset;
        }
    }
    &:disabled{
        opacity: 0.6;
        pointer-events: none;
        cursor: default;
    }
}